import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                 
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/117/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA® Cellular Boost Obnovující noční krém proti
                    vráskám
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NTG-Cellular-Boost-NOCNI-krem-Crtn-50-3D2.jpg"
                      alt="NTG Cellular Boost NOCNI krem Crtn 50 3D2"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA® Cellular Boost Obnovující noční krém proti
                      vráskám
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                        <span
                          style={{
                            "font-size": "large"
                          }}
                        >
                          NOVINKA{" "}
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <span
                          style={{
                            "font-size": "large"
                          }}
                        >
                          NEUTROGENA® Cellular Boost Obnovující noční krém proti
                          vráskám
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <span
                          style={{
                            "font-size": "small"
                          }}
                        >
                          Zmírňuje viditelné známky stárnutí způsobené buněčným
                          vyhořením.&nbsp;
                          <strong>Viditelné výsledky již za 4 týdny.</strong>**
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p className="TableParagraph">
                        Buněčné vyhoření zpomaluje procesy v buňkách pokožky a
                        je tak jednou z hlavních příčin stárnutí pleti.
                        Důsledkem je ztráta elasticity, vznik vrásek a
                        nejednotný tón pleti. Příčinami buněčného vyhoření jsou
                        zejména UV záření, znečištění ovzduší, stres a únava.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Klinicky prověřené složení krému obsahuje:
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        - <strong>Omlazující technologii Hexinol™</strong>,
                        která stimuluje přirozenou produkci kolagenu a elastinu
                        a podporuje obnovu pokožky.***
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        - <strong>Vitamín C*</strong>, který napomáhá sjednotit
                        tón pleti a redukovat tmavé skvrny.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Klinicky testováno.
                        <strong> Vhodné pro citlivou pleť.</strong>
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Řada NEUTROGENA® Cellular Boost byla vyvinuta ve
                        spolupráci s dermatology. Vyhlazuje vrásky, viditelně
                        zlepšuje elasticitu a tón pleti a navrací jí energii.
                        Vaše pleť je každou noc regenerována a intenzivně
                        vyživována.
                      </p>
                      <p className="TableParagraph">
                        &nbsp;Objevte celou řadu NEUTROGENA® Cellular Boost pro
                        kompletní péči o Vaši pleť.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p>
                        <span
                          style={{
                            "font-size": "x-small"
                          }}
                        >
                          *Derivát vitamínu C. **Klinická studie, 42 žen, které
                          používaly krém dvakrát denně. ***Testováno
                          laboratorně.
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Aplikujte na důkladně vyčištěnou pokožku obličeje a krku.
                      Pro dosažení nejlepších výsledků používejte v kombinaci s
                      Omlazujícím denním krémem s SPF 20.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>50 ml</dd>
                  </dl>
                </div>
                <div id="fb_62"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
